import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../components/AtiraIcon';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Input } from '../../components/Input';
import { CreateNerveDto } from '../../model/nerve/dto/CreateNerveDto';
import { useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';

const StyleInput = styled(Input)`
  height: 3rem !important;
  font-size: 1.3rem;
`;

export const NerveBasicInfo: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<CreateNerveDto>();
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);

  return (
    <Flex width={'100%'} justifyContent="center">
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="xl"
        width="70%"
      >
        <Flex gap="l" alignItems="center" width="100%">
          {loggedInUser?.picture ? (
            <AtiraImage
              width="6rem"
              src={loggedInUser?.picture}
              style={{ borderRadius: '50%' }}
            />
          ) : (
            <AtiraIcon icon={faCircleUser} />
          )}

          <Flex width={'calc(100% - 6rem)'}>
            <Controller
              control={control}
              name="name"
              render={({ field: { value, onChange } }) => (
                <StyleInput
                  iconColor="main"
                  iconSize="2x"
                  icon={faCircleUser}
                  placeholder={t('common.name')}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Flex>
        </Flex>

        <Flex gap="l" flexDirection="column" width={'100%'}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { value, onChange } }) => (
              <StyleInput
                iconColor="main"
                iconSize="2x"
                icon={faPhone}
                placeholder="+90531xxxxxxx"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            render={({ field: { value, onChange } }) => (
              <StyleInput
                iconColor="main"
                iconSize="2x"
                icon={faEnvelope}
                placeholder="xxxx@gmail.com"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="map"
            render={({ field: { value, onChange } }) => (
              <StyleInput
                iconColor="main"
                iconSize="2x"
                icon={faMap}
                placeholder={t('common.map')}
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="bio"
            render={({ field: { value, onChange } }) => (
              <StyleInput
                iconColor="main"
                iconSize="2x"
                icon={faFileAlt}
                placeholder={t('common.bio')}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
