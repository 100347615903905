import { AtiraRoute } from '../AtiraRoute';
import { QRGenerator } from '../QR-generator/QRGenerator';
import { QRRoute } from '../QR-generator/QRRoute';
import { Nerve } from '../nerve/Nerve';
import { NerveRoute } from '../nerve/NerveRoute';
import { URLRoute } from '../url/URLRoute';
import { URLShortner } from '../url/URLShortner';

export const PrivateRouter = [
  {
    path: '/',
    element: <AtiraRoute redirect="/nerve" />,
  },
  {
    path: '/url-shortner',
    element: <AtiraRoute component={<URLRoute />} />,
  },
  {
    path: '/qr-code',
    element: <AtiraRoute component={<QRRoute />} />,
  },
  {
    path: '/nerve',
    element: <AtiraRoute component={<NerveRoute />} />,
  },
  {
    path: '/nerve/create',
    element: <AtiraRoute component={<Nerve />} />,
  },
  {
    path: '/qr-code/create',
    element: <AtiraRoute component={<QRGenerator />} />,
  },
  {
    path: '/url-shortner/create',
    element: <AtiraRoute component={<URLShortner />} />,
  },
];
