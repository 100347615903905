import React, { useState } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { CreateNerveDto } from '../../model/nerve/dto/CreateNerveDto';

const StyleInput = styled(Input)`
  width: 20rem;
  height: 3rem !important;
  font-size: 1rem;
`;

const StyledButton = styled(Button)`
  width: 7rem;
  height: 3rem;
  font-size: 1rem;
`;

const StyleLink = styled(Link)`
  font-size: 1.5rem;
`;

export const NerveCustomLinks: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<CreateNerveDto>();
  const { fields, append, remove } = useFieldArray({
    name: 'customLinks',
    control,
  });

  const [customLink, setCustomLink] = useState('');

  const appendCustomLink = () => {
    append({ content: customLink });
    setCustomLink('');
  };

  return (
    <Flex
      width={'100%'}
      height={'30rem'}
      flexDirection="column"
      justifyContent="start"
      gap="xxl"
    >
      <Flex flexDirection="column" alignItems="center" gap="m">
        <Text>{t('nerves.custom_link.description')}</Text>
        <StyleInput
          placeholder={t('nerves.custom_link.placeholder')}
          value={customLink}
          onChange={(e) => setCustomLink(e.target.value)}
        />
        <StyledButton disabled={fields.length > 1} onClick={appendCustomLink}>
          {t('common.add')}+
        </StyledButton>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="s"
        padding="s"
      >
        <Flex flexDirection="column" gap="s">
          {fields.map(({ content: link }, index) => (
            <Flex alignItems="center" gap="xxl" key={link}>
              <StyleLink to={link} key={link}>
                {link}
              </StyleLink>
              <Button onClick={() => remove(index)}>
                {t('common.delete')}
              </Button>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
