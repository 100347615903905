import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { urlAdapterSelectors } from './url.slice';

const urlState = (state: RootState) => state.url;

const selectShortURLs = createSelector(
  [urlState, (_, userId: string) => userId],
  (state, userId) =>
    urlAdapterSelectors.selectById(state.urls, userId)?.urls || [],
);

export const urlSliceSelectors = { selectShortURLs };
