import { AtiraQR } from '../../model/qr/AtiraQR';
import { DataType } from '../../model/qr/Types/DataType.enum';

export class QRUtils {
  static generateQRImgURL(qr: AtiraQR) {
    const {
      fields: { color, bgcolor, format, data, size },
    } = qr;

    const imgUrl = `https://api.qrserver.com/v1/create-qr-code/?size=${size}&data=${encodeURIComponent(data)}&bgcolor=${encodeURIComponent(bgcolor.substring(1))}&color=${color.substring(1)}&format=${format}`;
    return imgUrl;
  }

  static transformDataBasedOnType(data: string, dataType: DataType): string {
    switch (dataType) {
      case DataType.PHONE:
        return `tel:${data}`;

      case DataType.SMS:
        return `sms:${data}`;

      case DataType.EMAIL:
        return `mailto:${data}`;

      case DataType.TEXT:
        return data;

      case DataType.URL:
        return data.startsWith('http') || data.startsWith('https')
          ? data
          : `https://${data}`;
    }
  }
}
