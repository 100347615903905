import { faGauge } from '@fortawesome/free-solid-svg-icons/faGauge';
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import { faTableList } from '@fortawesome/free-solid-svg-icons/faTableList';
import { Menu, MenuProps } from 'antd';
import { TFunction } from 'i18next';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { SideBarRoutes } from '../routes/types/SideBarRoutes.enum';
import { Theme } from '../theme/Theme';
import { AtiraIcon } from './AtiraIcon';

const getMenuItems = (config: {
  t: TFunction;
  navigate: NavigateFunction;
  collapsed: boolean;
}): Required<MenuProps>['items'] => {
  const { t, navigate, collapsed } = config;

  const genericItemStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    margin: '0',
    fontSize: '1.3rem',
    color: Theme.sub,
    borderRadius: '0',
    height: '3.3rem',
    width: '100%',
  };

  return [
    {
      key: SideBarRoutes.NERVE,
      icon: <AtiraIcon icon={faGauge} />,
      label: !collapsed ? t('side_menu.nerve') : null,
      onClick: () => navigate(SideBarRoutes.NERVE),
      style: genericItemStyle,
    },
    {
      key: SideBarRoutes.QR_GENERATOR,
      icon: <AtiraIcon icon={faTableList} />,
      label: !collapsed ? t('side_menu.qr_generator') : null,
      onClick: () => navigate(SideBarRoutes.QR_GENERATOR),
      style: genericItemStyle,
    },
    {
      key: SideBarRoutes.URL_SHORTNER,
      icon: <AtiraIcon icon={faTable} />,
      label: !collapsed ? t('side_menu.url_shortner') : null,
      onClick: () => navigate(SideBarRoutes.URL_SHORTNER),
      style: genericItemStyle,
    },
  ];
};

export const SideMenu: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getSelectedKey = useMemo(() => {
    switch (true) {
      case pathname.startsWith(SideBarRoutes.NERVE):
        return SideBarRoutes.NERVE;
      case pathname.startsWith(SideBarRoutes.QR_GENERATOR):
        return SideBarRoutes.QR_GENERATOR;
      case pathname.startsWith(SideBarRoutes.URL_SHORTNER):
        return SideBarRoutes.URL_SHORTNER;
      default:
        return pathname;
    }
  }, [pathname]);

  return (
    <Menu
      style={{
        position: 'relative',
        top: '3.5rem',
        backgroundColor: 'transparent',
        padding: '0',
      }}
      mode="inline"
      theme="dark"
      items={getMenuItems({ t, navigate, collapsed })}
      selectedKeys={[getSelectedKey]}
    />
  );
};
