import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { CreateNerveDto } from '../../model/nerve/dto/CreateNerveDto';
import { NerveActions } from '../../redux/nerve/nerve.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';

const IDInput = styled(Input)`
  width: 7rem !important;
  border-radius: 0;
  border: 0 !important;
  border-bottom: 2px solid ${({ theme }) => theme.darkerSub} !important;

  &:focus {
    border-bottom: 3px solid ${({ theme }) => theme.main} !important;
  }
  background-color: ${(props) => props.theme.transparent};
  font-size: 1.5rem;
  transition: border-color 0.5s;
`;

export const NerveID: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { getValues, handleSubmit, control, watch, reset } = useFormContext<
    CreateNerveDto & { nerveName: string }
  >();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);

  const dispatch = useAppDispatch();

  const nerveName = watch('nerveName');

  const createNerve = async () => {
    try {
      setLoading(true);

      const payload: CreateNerveDto = getValues();
      payload.image = loggedInUser?.picture;
      payload.customLinks = payload.customLinks?.map(
        ({ content }) => content,
      ) as any;

      await dispatch(NerveActions.createUserNeve(payload)).unwrap();
      await dispatch(NerveActions.getUserNerves({ userId: userId })).unwrap();

      AtiraToast.success(t('nerves.create.success'));
      reset();
    } catch (e: any) {
      console.error(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      width={'100%'}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="xl"
    >
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        gap="s"
        width={'70%'}
      >
        <Flex gap="s" justifyContent="center">
          <Flex>
            <Text fontSize="xl" fontWeight={700}>
              https://nrvl.ink/
            </Text>
          </Flex>

          <Controller
            name="nerveName"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('common.this_field_required'),
              },
              maxLength: 7,
              minLength: 3,
            }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <IDInput
                onChange={onChange}
                value={value}
                maxLength={7}
                minLength={3}
                valid={isEmpty(errors.nerveName)}
              />
            )}
          />
        </Flex>

        <Flex>
          <Text color="gray" fontSize="s">
            {`${t('nerves.create.steps.nerve_name.link_preview_paragraph')}: `}
            https://nrvl.ink/
            {nerveName}-7uZ
          </Text>
        </Flex>
      </Flex>
      <Button
        onClick={handleSubmit(createNerve)}
        height="3.1rem"
        width="10rem"
        fontSize="1rem"
        padding="0"
        loading={loading}
        disabled={nerveName.length < 3 || nerveName.length > 7}
      >
        {t('nerves.create.steps.nerve_name.create_button')}
      </Button>
    </Flex>
  );
};
