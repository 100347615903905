import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { NerveActions } from '../../redux/nerve/nerve.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { UserNerves } from './UserNerves';

export const NerveRoute: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  useEffect(() => {
    const fetchNerves = async () => {
      try {
        await dispatch(
          NerveActions.getUserNerves({ userId: loggedInUserId }),
        ).unwrap();
      } catch (e: any) {
        console.log(e);
      }
    };

    fetchNerves();
  }, [dispatch, loggedInUserId]);

  return (
    <Flex flex={1} flexDirection="column">
      <SubHeader
        title={t('subheader.nerve')}
        buttonTitle={t('common.create')}
        icon={faPlus}
        onClick={() => navigate('/nerve/create')}
      />
      <UserNerves />
    </Flex>
  );
};
