import { Features } from '../../Features';
import { AtiraRouteNotLoggedIn } from '../AtiraRouteNotLoggedIn';
import { Login } from '../loginSignup/Login';
import { ForgotPassword } from '../loginSignup/forgot-password/ForgotPassword';
import { Signup } from '../loginSignup/signup/Signup';

export const PublicRouter = [
  {
    path: '/login',
    element: <AtiraRouteNotLoggedIn component={<Login />} />,
  },
  {
    path: '/signup',
    element: <AtiraRouteNotLoggedIn component={<Signup />} />,
  },
  {
    path: '/forgot-password',
    element: <AtiraRouteNotLoggedIn component={<ForgotPassword />} />,
  },
  {
    path: '/features',
    element: <AtiraRouteNotLoggedIn component={<Features />} />,
  },
];
