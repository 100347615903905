import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const nerveState = (state: RootState) => state.nerveSlice;

const selectUserNerves = createSelector(
  nerveState,
  (state) => state.nerves || [],
);

export const nerveSliceSelectors = { selectUserNerves };
