import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { AtiraError } from '../../components/AtiraError';
import { AtiraIcon } from '../../components/AtiraIcon';
import { Button } from '../../components/Button';
import { CopyButton } from '../../components/CopyButton';
import { DeleteButton } from '../../components/DeleteButton';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { WarningModal } from '../../components/WarningModal';
import { nerveSliceSelectors } from '../../redux/nerve/nerve.selector';
import { NerveActions } from '../../redux/nerve/nerve.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';

const NerveContainer = styled(Flex)`
  justify-content: center;
  width: 25%;
`;

const TableHeader = styled(Text)`
  font-size: 1.5rem;
`;

export const UserNerves: React.FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();

  const nerves = useAppSelector(nerveSliceSelectors.selectUserNerves);

  const [currentNerveId, setCurrentNerveId] = useState('');
  const [deleteFormModalVisibile, setDeleteFormModalVisibile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableEnableNerveLoadingIndex, setDisableEnableNerveLoadingIndex] =
    useState<number | undefined>(undefined);

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const dispatch = useAppDispatch();

  if (!nerves.length) {
    return <AtiraError errorMessage={t('nerves.no_nerevs')} />;
  }

  const deleteNerveWarning = (nerveId: string) => {
    setCurrentNerveId(nerveId);
    setDeleteFormModalVisibile(true);
  };

  const onShorten = (link: string) => {
    navigate('/url-shortner', {
      state: {
        nerveURL: `${link}`,
        tabIndex: 1,
      },
    });
  };

  const toggleNerveStatus = async (
    nerveId: string,
    enabled: boolean,
    index: number,
  ) => {
    try {
      setDisableEnableNerveLoadingIndex(index);

      if (enabled) {
        await dispatch(
          NerveActions.disableUserNerve({
            nerveId,
            userId: loggedInUserId,
          }),
        ).unwrap();
        AtiraToast.success(t('nerves.disable.success'));
      } else {
        await dispatch(
          NerveActions.enableUserNerve({
            nerveId,
            userId: loggedInUserId,
          }),
        ).unwrap();
        AtiraToast.success(t('nerves.enable.success'));
      }

      await dispatch(
        NerveActions.getUserNerves({ userId: loggedInUserId }),
      ).unwrap();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setDisableEnableNerveLoadingIndex(undefined);
    }
  };

  const deleteNerve = async () => {
    try {
      setLoading(true);

      await dispatch(
        NerveActions.deleteUserNerve({
          nerveId: currentNerveId,
          userId: loggedInUserId,
        }),
      ).unwrap();

      setDeleteFormModalVisibile(false);

      await dispatch(
        NerveActions.getUserNerves({ userId: loggedInUserId }),
      ).unwrap();

      AtiraToast.success(t('nerves.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
      setCurrentNerveId('');
    }
  };

  return (
    <Flex
      width="100%"
      height={'100%'}
      flexDirection="column"
      alignItems="center"
    >
      <Flex width={'100%'} flexDirection="column" alignItems="center">
        <Flex
          width={'100%'}
          justifyContent="space-between"
          alignItems="center"
          backgroundColor={theme.lightergray}
          padding="m"
        >
          <NerveContainer>
            <TableHeader>{t('nerves.table.header.nerve')}</TableHeader>
          </NerveContainer>

          <NerveContainer>
            <TableHeader>{t('common.status')}</TableHeader>
          </NerveContainer>

          <NerveContainer>
            <TableHeader>{t('common.click_count')}</TableHeader>
          </NerveContainer>

          <NerveContainer>
            <TableHeader>{t('common.actions')}</TableHeader>
          </NerveContainer>
        </Flex>

        {nerves.map((nerve, index) => {
          const link = `${process.env.REACT_APP_NERVE_URL}/${nerve.nerveName}-${nerve.nerveId}`;
          return (
            <Flex
              key={nerve._id}
              width={'100%'}
              justifyContent="space-between"
              alignItems="center"
              marginTop="s"
            >
              <NerveContainer>
                <Text color={nerve.enabled ? 'black' : 'gray'}>{link}</Text>
              </NerveContainer>

              <NerveContainer
                alignItems="center"
                opacity={nerve.enabled ? 1 : 0.5}
              >
                <Tooltip
                  title={t(
                    `nerves.${nerve.enabled ? 'enabled' : 'disabled'}.message`,
                  )}
                >
                  <Button
                    loading={disableEnableNerveLoadingIndex === index}
                    onClick={() =>
                      toggleNerveStatus(nerve.nerveId, nerve.enabled, index)
                    }
                  >
                    {nerve.enabled ? t('common.enabled') : t('common.disabled')}
                  </Button>
                </Tooltip>
              </NerveContainer>

              <NerveContainer>
                <Text>{nerve.visits}</Text>
              </NerveContainer>

              <NerveContainer alignItems="center" gap="s">
                <Button
                  backgroundColor="transparent"
                  onClick={() => onShorten(link)}
                >
                  <Tooltip title={t('common.shorten')}>
                    <AtiraIcon icon={faPaperclip} />
                  </Tooltip>
                </Button>
                <CopyButton copy={link} />

                <DeleteButton
                  onDelete={() => deleteNerveWarning(nerve.nerveId)}
                />
              </NerveContainer>
            </Flex>
          );
        })}

        <WarningModal
          onClose={() => setDeleteFormModalVisibile(false)}
          open={deleteFormModalVisibile}
          title={t('nerves.delete.modal.title')}
          description={t('nerves.delete.modal.description')}
          onConfirm={deleteNerve}
          loading={loading}
        />
      </Flex>
    </Flex>
  );
};
