// @ts-nocheck
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';

interface AtiraRouteProps {
  component?: React.ReactNode;
  redirect?: string;
}

/** Makes sure only logged-in users can go to props.component */
export const AtiraRoute: React.FC<AtiraRouteProps> = ({
  component,
  redirect,
}) => {
  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  );
  const isLoggedIn = Boolean(loggedInUserId);

  if (!isLoggedIn) {
    return <Navigate to={'/login'} replace />;
  }

  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  return component;
};
