import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { t } from 'i18next';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../../components/AtiraIcon';
import { Button } from '../../components/Button';
import { DropDown } from '../../components/DropDown';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Input } from '../../components/Input';
import { SubHeader } from '../../components/SubHeader';
import { Text } from '../../components/Text';
import i18n from '../../i18n';
import { DataType } from '../../model/qr/Types/DataType.enum';
import { QRFormat } from '../../model/qr/Types/QRFormat.enum';
import { CreateQRDto } from '../../model/qr/dto/CreateQRDto';
import { QRActions } from '../../redux/QR/qr.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { QRUtils } from './QRUtils';
import { QRDownloadModal } from './components/QRDownloadModal';

const StyledInput = styled(Input)`
  border: 1px solid ${(props) => props.theme.black};
  height: 100%;
  padding: ${Spacing.s} ${Spacing.s};
  background-color: ${(props) => props.theme.transparent};
  color: ${(props) => props.theme.black};
  &::placeholder {
    color: ${(props) => props.theme.black};
  }
`;

const CustomizationInput = styled(Input)`
  border: 1px solid ${(props) => props.theme.black};
  height: 2.5rem;
  width: 20rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.transparent};
  padding: ${Spacing.s};
  color: ${(props) => props.theme.black};
`;

const CreateButton = styled(Button)`
  padding: 1rem 1.5rem;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  margin: 0;
`;

const dataTypesOptions = [
  { value: DataType.URL, label: t('common.url') },
  { value: DataType.EMAIL, label: t('common.email') },
  { value: DataType.PHONE, label: t('common.phone') },
  { value: DataType.SMS, label: t('common.sms') },
  { value: DataType.TEXT, label: t('common.text') },
];

const formatOptions = [
  { value: QRFormat.PNG, label: 'PNG' },
  { value: QRFormat.SVG, label: 'SVG' },
];

export const QRGenerator: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [previewURL, setPreviewURL] = useState('');
  const [qrDownloadModalVisible, setQrDownloadModalVisible] = useState(false);

  const { control, getValues, setValue, handleSubmit, reset, watch } =
    useForm<CreateQRDto>({
      defaultValues: {
        color: '#000000',
        bgcolor: '#ffffff',
        format: QRFormat.PNG,
        dataType: DataType.URL,
        size: 250,
        data: '',
      },
    });

  const selectedDataType = watch('dataType', DataType.URL);
  const selectedFormat = watch('format', QRFormat.PNG);
  const data = watch('data', '');

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const dto = getValues();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateQRPreview = useCallback(
    debounce(() => {
      const { size, bgcolor, color, data, format } = getValues();
      if (!data) {
        return;
      }
      const imgUrl = `https://api.qrserver.com/v1/create-qr-code/?size=${size}&data=${encodeURIComponent(data)}&bgcolor=${encodeURIComponent(bgcolor.substring(1))}&color=${color.substring(1)}&format=${format}`;
      setPreviewURL(imgUrl);
    }, 1000),
    [getValues],
  );

  const websiteLanguage = i18n.language;

  const createQR = async () => {
    try {
      setLoading(true);

      const payload = {
        ...getValues(),
        userId: loggedInUserId,
        data: QRUtils.transformDataBasedOnType(dto.data, dto.dataType),
        size: Number(dto.size),
      };

      await dispatch(QRActions.createQR(payload)).unwrap();
      await dispatch(QRActions.getUserQRs({ userId: loggedInUserId })).unwrap();

      reset({
        color: '#000000',
        bgcolor: '#ffffff',
        format: getValues('format'),
        dataType: DataType.URL,
        size: 250,
        data: '',
      });

      AtiraToast.success(t('qr.tabs.create_qr.create.success'));
    } catch (error) {
      console.log(error);
      AtiraToast.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="m"
    >
      <SubHeader
        title={`${t('subheader.qr_generator')} / ${t('common.create')}`}
        buttonTitle={t('common.back')}
        icon={websiteLanguage === 'ar' ? faArrowRight : faArrowLeft}
        onClick={() => navigate('/qr-code')}
      />
      <Text align="center">{t('qr..create_qr.paragraph')}</Text>

      <Flex width={'40%'}>
        <Flex
          width={'100%'}
          gap="xxl"
          justifyContent="center"
          alignItems="center"
          height={'3rem'}
        >
          <Flex justifyContent="center" alignItems="center">
            <Controller
              name="data"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('common.this_field_required'),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <StyledInput
                  type="text"
                  placeholder={t('qr.create.input.content.placeholder')}
                  value={value}
                  width="8rem"
                  onChange={(e) => {
                    onChange(e);
                    generateQRPreview();
                  }}
                  style={{ height: '2rem' }}
                />
              )}
            />
          </Flex>
          <Flex justifyContent="flex-start">
            <Controller
              name="dataType"
              control={control}
              render={({ field: { onChange } }) => (
                <DropDown
                  value={{
                    label: selectedDataType || t('common.dropdown.select'),
                    value: selectedDataType,
                  }}
                  options={dataTypesOptions}
                  onChange={(value: any) => {
                    onChange(value);
                    setValue('data', '');
                    setPreviewURL('');
                  }}
                  width="8rem"
                />
              )}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        width={'100%'}
        justifyContent="center"
        flexDirection="column"
        gap="s"
      >
        <Text fontSize="l" align="center">
          {t('qr.create.paragraph')}
        </Text>

        <Text align="center" color="gray" fontSize="s">
          {t('qr.create.save_note')}
        </Text>
      </Flex>

      <Flex width={'100%'} justifyContent="space-between" gap="xxl">
        <Flex
          flexDirection="column"
          alignSelf="flex-start"
          gap="m"
          marginLeft="xxl"
          width={'40%'}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width={'100%'}
            flexWrap="wrap"
          >
            <Text>{t('qr.create.input.size.label')}</Text>
            <Controller
              name="size"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomizationInput
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    generateQRPreview();
                  }}
                  type="number"
                  lang="en"
                  color="white"
                />
              )}
            />
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            width={'100%'}
            flexWrap="wrap"
          >
            <Text>{t('qr.create.input.color.label')}</Text>
            <Controller
              name="color"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomizationInput
                  type="color"
                  id="color"
                  padding="0"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    generateQRPreview();
                  }}
                />
              )}
            />
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            width={'100%'}
            flexWrap="wrap"
          >
            <Text>{t('qr.create.input.background_color.label')}</Text>
            <Controller
              name="bgcolor"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomizationInput
                  type="color"
                  id="bgcolor"
                  padding="0"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    generateQRPreview();
                  }}
                />
              )}
            />
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            width={'100%'}
            flexWrap="wrap"
          >
            <Text>{t('qr.create.input.image_type.label')}</Text>
            <Flex width={'20rem'}>
              <Controller
                name="format"
                control={control}
                render={({ field: { onChange } }) => (
                  <DropDown
                    value={{
                      label: selectedFormat || t('common.dropdown.select'),
                      value: selectedFormat,
                    }}
                    options={formatOptions}
                    onChange={(value: any) => {
                      onChange(value);
                      generateQRPreview();
                    }}
                    width="100%"
                  />
                )}
              />
            </Flex>
          </Flex>
        </Flex>

        {previewURL ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="m"
            width={'50%'}
            borderLeft="2px solid black"
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              gap="s"
              padding="m"
            >
              <AtiraImage
                src={previewURL}
                width={getValues('size').toString()}
                height={getValues('size').toString()}
                style={{ maxWidth: '8rem', maxHeight: '8rem' }}
              />

              <Text color="gray" fontSize="m">
                {`${dto.size} x ${dto.size}`}
              </Text>

              <Button
                onClick={() => setQrDownloadModalVisible(true)}
                padding="0"
                margin="0"
                backgroundColor="transparent"
              >
                <AtiraIcon icon={faDownload} />
              </Button>

              <Text color="gray" fontSize="s">
                {t('qr.create.size_note')}
              </Text>
            </Flex>
          </Flex>
        ) : null}
      </Flex>

      <Flex alignItems="center" gap="l" width={'8rem'} height={'3.5rem'}>
        <CreateButton
          loading={loading}
          onClick={handleSubmit(createQR)}
          disabled={!data}
        >
          {t('common.save')}
        </CreateButton>
      </Flex>

      <QRDownloadModal
        url={previewURL}
        isOpen={qrDownloadModalVisible}
        onClose={() => setQrDownloadModalVisible(false)}
      />
    </Flex>
  );
};
