import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { shortenUrlActions } from '../../redux/url/url.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { UserURLs } from './UserURLs';

export const URLRoute: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  useEffect(() => {
    const getUserShortURLs = async () => {
      try {
        await dispatch(
          shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
        ).unwrap();
      } catch (e: any) {
        console.log(e);
        AtiraToast.apiError(e);
      }
    };

    getUserShortURLs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column">
      <SubHeader
        title={t('subheader.url_shortner')}
        icon={faPlus}
        buttonTitle={t('common.create')}
        onClick={() => navigate('/url-shortner/create')}
      />
      <UserURLs />
    </Flex>
  );
};
