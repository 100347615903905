import { Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from 'antd';

import i18n, { AppLangs } from '../i18n';

type DrawerProps = AntdDrawerProps;

export const Drawer: React.FC<DrawerProps> = ({ children, ...props }) => {
  const lang = i18n.language as AppLangs;

  return (
    <AntdDrawer placement={lang === AppLangs.AR ? 'left' : 'right'} {...props}>
      {children}
    </AntdDrawer>
  );
};
