import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { DeleteButton } from '../../../components/DeleteButton';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { WarningModal } from '../../../components/WarningModal';
import { AtiraQR } from '../../../model/qr/AtiraQR';
import { QRActions } from '../../../redux/QR/qr.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { QRUtils } from '../QRUtils';
import { QRDownloadModal } from './QRDownloadModal';

const StyledText = styled(Text)`
  width: 10rem;
`;

interface QRCodeProps {
  qr: AtiraQR;
  showDeleteButton?: boolean;
}

export const QRCode: React.FC<QRCodeProps> = ({
  qr,
  showDeleteButton = true,
}) => {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileNameModalVisible, setFileNameModalVisible] = useState(false);

  const { t } = useTranslation();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const dispatch = useAppDispatch();

  const {
    fields: { data, size },
  } = qr;

  const imgUrl = QRUtils.generateQRImgURL(qr);

  const deleteQRWarning = () => {
    setIsWarningModalOpen(true);
  };

  const deleteQR = async () => {
    try {
      setLoading(true);

      await dispatch(
        QRActions.deleteQR({
          qrId: qr._id,
          userId: loggedInUserId,
        }),
      ).unwrap();

      await dispatch(QRActions.getUserQRs({ userId: loggedInUserId })).unwrap();

      AtiraToast.success(t('qr.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
      setIsWarningModalOpen(false);
    }
  };

  return (
    <Flex
      gap="s"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      width={'10rem'}
      height={'20rem'}
    >
      <AtiraImage
        style={{ height: 'auto', maxWidth: '10rem' }}
        src={imgUrl}
        width={`${size}px`}
        height={`${size}px`}
      />

      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Flex alignItems="center" justifyContent="center">
          <StyledText align="center" fontSize="l" cutText>
            {data}
          </StyledText>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Text align="center" color="gray">
            {size}
          </Text>

          <Flex>
            {showDeleteButton ? (
              <Flex>
                <DeleteButton onDelete={deleteQRWarning} />
              </Flex>
            ) : null}

            <Tooltip title={t('common.download')}>
              <Button
                onClick={() => setFileNameModalVisible(true)}
                backgroundColor="transparent"
              >
                <AtiraIcon icon={faDownload} />
              </Button>
            </Tooltip>
          </Flex>

          <WarningModal
            open={isWarningModalOpen}
            onClose={() => setIsWarningModalOpen(false)}
            title={t('qr.delete.modal.title')}
            description={t('qr.delete.modal.description')}
            onConfirm={deleteQR}
            loading={loading}
          />

          <QRDownloadModal
            isOpen={fileNameModalVisible}
            url={imgUrl}
            onClose={() => setFileNameModalVisible(false)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
