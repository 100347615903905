import { Col, Layout, Row } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { Images } from './assets';
import { Button } from './components/Button';
import { Flex } from './components/Flex';
import { AtiraImage } from './components/Image';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { Text } from './components/Text';
import { Breakpoints } from './theme/Breakpoints';
import { Rounded } from './theme/Rounded';
import { Spacing } from './theme/Spacing';

const headerStyle: React.CSSProperties = {
  width: '100%',
  background:
    'linear-gradient(90deg, rgb(151, 11, 170) 0%, rgb(35, 40, 147) 99%)',
};

const contentStyle: React.CSSProperties = {
  background:
    'linear-gradient(90deg, rgba(35,40,147,1) 41%, rgba(151,11,170,1) 76%)',
  padding: '3rem',
};

const footerStyle: React.CSSProperties = {
  background:
    'linear-gradient(90deg, rgb(151, 11, 170) 0%, rgb(35, 40, 147) 99%)',
  padding: '4rem',
};

const HeaderLinks = styled(Text)`
  color: ${(props) => props.theme.white};

  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    color: ${(props) => props.theme.main};
    border-radius: ${Rounded.sm};
  }
`;

const StartButton = styled(Button)`
  text-decoration: none;
  color: ${(props) => props.theme.main};
  padding: ${Spacing.s} ${Spacing.m};
  margin: 0;
  border-radius: ${Rounded.pill};
  background-color: ${(props) => props.theme.white};
  font-size: 0.75rem;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    color: ${(props) => props.theme.black};
  }
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

const HeroCircleAnimation = keyframes`
  from {
    transform: translateY(-1rem);
  } to {
    transform: translateY(1rem);
  }
`;

const HeroImageLeftCircle = styled(Flex)`
  height: 4rem;
  width: 4rem;
  position: absolute;
  bottom: 5rem;
  left: 0;
  border-radius: ${Rounded.circle};
  background-size: 20rem;
  padding: ${Spacing.m};
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 1111;
  animation: ${HeroCircleAnimation} infinite ease-in-out alternate 1.5s;
  @media (min-width: ${Breakpoints.TABLET}) {
    height: 6rem;
    width: 6rem;
  }
`;

const HeroImageRightCircle = styled(Flex)`
  height: 5rem;
  width: 5rem;
  background-color: ${(props) => props.theme.darkPink};
  position: absolute;
  bottom: 5rem;
  right: 3rem;
  border-radius: ${Rounded.circle};
  padding: ${Spacing.m};
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  animation: ${HeroCircleAnimation} infinite ease-in-out alternate 2s;
  @media (min-width: ${Breakpoints.TABLET}) {
    height: 8rem;
    width: 8rem;
  }
`;

const CallToAction = styled(Button)`
  color: ${(props) => props.theme.main};
  background-color: ${(props) => props.theme.white};
  padding: 0.75rem 1rem;
  width: 20rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.25rem;
  }
`;

export const Features: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <Flex>
      <Layout>
        <Header style={headerStyle}>
          <Row justify="space-evenly">
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Flex>
                <AtiraImage width="50%" src={Images.AtiraSpaceLogo} />
              </Flex>
            </Col>
            <Col xs={12} sm={4} md={12} lg={8} xl={8}>
              <Flex gap="l" alignItems="center" justifyContent="center">
                <Link to={''}>
                  <HeaderLinks> {t('common.how_to')} </HeaderLinks>
                </Link>
                <Link to={''}>
                  <HeaderLinks>{t('common.templates')} </HeaderLinks>
                </Link>{' '}
                <Link to={''}>
                  <HeaderLinks>{t('common.pricing')}</HeaderLinks>
                </Link>{' '}
                <Link to={''}>
                  <HeaderLinks>{t('common.help')}</HeaderLinks>
                </Link>
              </Flex>
            </Col>
            <Col xs={12} sm={4} md={12} lg={8} xl={8}>
              <Flex
                gap="m"
                alignItems="center"
                justifyContent="end"
                height={'100%'}
              >
                <LanguageSwitcher />

                <StartButton onClick={() => navigate('/login')}>
                  {t('common.login')}
                </StartButton>

                <StartButton onClick={() => navigate('/signup')}>
                  {t('common.signup')}
                </StartButton>
              </Flex>
            </Col>
          </Row>
        </Header>

        <Content style={contentStyle}>
          <Row justify={'space-between'}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={9}
              xl={9}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Flex flexDirection="column" gap="m">
                <Text fontSize="xxxl" fontWeight={'bolder'} color="white">
                  {t('features.nerve.first_section.main_title')}
                </Text>
                <Text fontSize="xxl" fontWeight={'bolder'} color="white">
                  {t('features.nerve.first_section.title')}
                </Text>
                <Text fontSize="xl" color="white">
                  {t('features.nerve.first_section.description')}
                </Text>
                <CallToAction onClick={() => navigate('/signup')}>
                  {t('front_page.hero.main_c2a')}
                </CallToAction>
              </Flex>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={11}>
              <Flex>
                <HeroImageLeftCircle>
                  <AtiraImage src={Images.FeaturesFirstCircle} />
                </HeroImageLeftCircle>
                <AtiraImage
                  src={Images.FeaturesNerve1}
                  width="45%"
                  style={{
                    left: '0rem',
                    position: 'absolute',
                    transform: 'rotate(-4deg)',
                    borderRadius: '2rem',
                  }}
                />
                <AtiraImage
                  src={Images.FeaturesNerve2}
                  width="45%"
                  style={{
                    transform: 'rotate(4deg)',
                    borderRadius: '2rem',
                  }}
                />
                <HeroImageRightCircle>
                  <AtiraImage src={Images.FeaturesSecondCircle} />
                </HeroImageRightCircle>
              </Flex>
            </Col>
          </Row>

          <Row style={{ marginTop: '5rem' }}>
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: 24, order: 2 }}
              md={{ span: 12, order: 2 }}
              lg={{ span: 12, order: 1 }}
              xl={{ span: 12, order: 1 }}
            >
              <AtiraImage src={Images.FeaturesNerve3} width="70%" />
            </Col>

            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12, order: 2 }}
              xl={{ span: 12, order: 2 }}
            >
              <Flex flexDirection="column" gap="m">
                <Text fontSize="xxl" fontWeight={'bolder'} color="white">
                  {t('features.nerve.second_section.title')}
                </Text>
                <Text fontSize="l" color="white">
                  {t('features.nerve.second_section.description')}
                </Text>
                <Row>
                  <Col xs={24} sm={24} md={6} lg={8} xl={8}>
                    <Flex flexDirection="column" gap="m">
                      <AtiraImage
                        src={Images.FeaturesSecondSection1}
                        width="45%"
                      />
                      <Text fontSize="xxl" color="white">
                        {t('features.nerve.second_section.card_title_1')}
                      </Text>
                      <Text color="white">
                        {t('features.nerve.second_section.card_description_1')}
                      </Text>
                    </Flex>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} xl={8}>
                    <Flex flexDirection="column" gap="m">
                      <AtiraImage
                        src={Images.FeaturesSecondSection2}
                        width="45%"
                      />
                      <Text fontSize="xxl" color="white">
                        {t('features.nerve.second_section.card_title_2')}
                      </Text>
                      <Text color="white">
                        {t('features.nerve.second_section.card_description_2')}
                      </Text>
                    </Flex>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} xl={8}>
                    <Flex flexDirection="column" gap="m">
                      <AtiraImage
                        src={Images.FeaturesSecondSection3}
                        width="45%"
                      />
                      <Text fontSize="xxl" color="white">
                        {t('features.nerve.second_section.card_title_3')}
                      </Text>
                      <Text color="white">
                        {t('features.nerve.second_section.card_description_3')}
                      </Text>
                    </Flex>
                  </Col>
                </Row>
              </Flex>
            </Col>
          </Row>
        </Content>

        <Footer style={footerStyle}>
          <Row justify={'space-between'}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Flex flexDirection="column" gap="m">
                <AtiraImage src={Images.AtiraSpaceLogo} width="100%" />
                <Text fontSize="l" align="center" color="white">
                  {t('features.nerve.first_section.title')}
                </Text>
              </Flex>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Flex flexDirection="column" gap="m" alignItems="center">
                <Text fontSize="l" fontWeight={'bold'} color="white">
                  {t('common.links')}
                </Text>
                <Flex flexDirection="column" alignItems="center" gap="s">
                  <Link to={''}>
                    <Text color="white">{t('common.how_to')} </Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.templates')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.pricing')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.help')}</Text>
                  </Link>
                </Flex>
              </Flex>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Flex flexDirection="column" gap="m" alignItems="center">
                <Text fontSize="l" fontWeight={'bold'} color="white">
                  {t('common.links')}
                </Text>
                <Flex flexDirection="column" alignItems="center" gap="s">
                  <Link to={''}>
                    <Text color="white">{t('common.how_to')} </Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.templates')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.pricing')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.help')}</Text>
                  </Link>
                </Flex>
              </Flex>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Flex flexDirection="column" gap="m" alignItems="center">
                <Text fontSize="l" fontWeight={'bold'} color="white">
                  {t('common.links')}
                </Text>
                <Flex flexDirection="column" alignItems="center" gap="s">
                  <Link to={''}>
                    <Text color="white">{t('common.how_to')} </Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.templates')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.pricing')}</Text>
                  </Link>
                  <Link to={''}>
                    <Text color="white">{t('common.help')}</Text>
                  </Link>
                </Flex>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Text align="center" color="white">
                {t('app.all_rights_reserved')}
              </Text>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Flex>
  );
};
