import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { QRAdapterSelectors } from './qr.slice';

const QRState = (state: RootState) => state.qr;

const selectAllQRs = createSelector(
  [QRState, (_, userId: string) => userId],
  (state, userId) =>
    QRAdapterSelectors.selectById(state.qrAdapter, userId)?.QRs || [],
);

export const QRSliceSelectors = { selectAllQRs };
